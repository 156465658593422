.flop-radio-input {
  cursor: pointer;
  background: #7694b3;
}

.flop-radio-input-checked {
  background: linear-gradient(90deg, #f5aa52 0%, #fe5f55 100%);
}

.flop-radio-input-checked .flop-radio-inner-container {
  background: linear-gradient(90deg, #f5aa52 0%, #fe5f55 100%);
}

.flop-radio-input:not(.flop-radio-input-checked):hover
  .flop-radio-inner-container {
  background: #7694b350 !important;
}

.flop-radio-input:not(.flop-radio-input-checked):active
  .flop-radio-inner-container {
  background: #7694b375 !important;
}
